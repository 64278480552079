import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import ContactForm from "../components/contact_form"
import ContactForm from "../components/contact_form"

import { Jumbotron, Container, Row, Col } from "react-bootstrap"

const Equipe = () => (
  <Layout>
    <SEO title="Contact" />
    <Jumbotron fluid>
      <Container>
        <h1>Contact</h1>
      </Container>
    </Jumbotron>
    <Container fluid="md" className="py-5">
      <Row style={{ fontSize: "0.9em" }}>
        <Col md={4} className="pt-2">
          <h4>Adresse postale</h4>
          <hr />
          <address>
            Cap Pêche Loisirs <br />
            59 rue de la Semie <br />
            40130 Capbreton, France <br />
          </address>
        </Col>
        <Col md={4} className="pt-2">
          <h4>Téléphone</h4>
          <hr />
          Nous sommes joignables au
          <a className="mx-1" href="tel:+33675737251">
            06 75 73 72 51
          </a>
          .
        </Col>
        <Col md={4} className="pt-2">
          <h4>Adresse email</h4>
          <hr />
          Écrivez-nous à l'adresse email
          <a className="mx-1" href="mailto:contact@cappecheloisirs.com">
            contact@cappecheloisirs.com
          </a>
          ou directement depuis le formulaire de contact présent en bas de page.
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Equipe
